

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ImageWithId, ProductById, ProductVariant} from "@/graphql/types/types";
import {SEND_FEEDBACK} from "@/graphql/queries/adminSettings";

@Component
export default class FeedbackButton extends Vue {

    private dialog: boolean = false;

    private feedback: string = ""

    private loading: boolean = false;

    async sendFeedback() {
        this.loading = true;

        await this.$apollo.mutate({
            mutation: SEND_FEEDBACK,
            variables: {
                feedback: this.feedback
            }
        })


        this.loading = false;
        this.dialog = false;
        this.feedback = "";

        this.$store.commit("setSnackbar", this.$t('feedback.send.succesful'));
    }
}

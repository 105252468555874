import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from "@/plugins/translations/t";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                accent: "#C29D4E",
                background: '#f5f5f5',
                primary: "#C29D4E"
            }
        }
    },
    lang: {
        // @ts-ignore
        t: (key, ...params) => i18n.t(key, params),
    },
});

export default {
    "home": {
        "totalSales": "Total sales",
        "itemsSold": "Items sold",
        "todo": "My active orders",
        "orderName": "Order Name",
        "createdAt": "Created at",
        "downloadLabel": "Download label",
        "last30day": "Last 30 day overview"
    },
    "downloadLabel": "Download",
    "logout": "Log out",
    "homePage": "Home",
    "orders": "Orders",
    "products": "Products",
    "settings": {
        "label": "Settings",
        "error": {
            "name": "Name must be set",
            "emailMissing": "Email must be set",
            "charge": "Charge must be between 0 and 100%",
            "emailValid": "Please enter a valid email",
            "emailTooLong": "The maximum length of an email address is 255 characters",
            "iban": "Please enter a valid IBAN number",
            "textTooLong": "Maximum text length is 1024 characters",
            "phoneNr": "Please enter a valid phone number",
            "validDate": "Please enter a date in format DD.MM.YYYY",
            "partnerMissing": "Please select a Partner"
        },
        "action": {
            "saved": "Settings saved"
        },
        "partnerName": "Partner name",
        "externalName": "Partner external name",
        "email": "Email address",
        "upcharge": "Ehestu mark-up",
        "chargeExpl": {
            "text1": "Retail price is set as",
            "text2": "item cost * vat * (1 + upcharge / 100)",
            "text3": "E.x."
        },
        "chargePerItem": "Markup can be set per item",
        "deliveryTime": {
            "label": "Global delivery time (in days)",
            "min": "Min",
            "max": "Max"
        },
        "additionalEmails": "Additional email addresses",
        "addAdditionalEmail": "Add new email",
        "generalPartnerInfo": "General partner information",
        "partnerDescription": "Partner description",
        "homepage": "Partner homepage",
        "iban": "Partner iban account",
        "logoUrl": "Partner logo url",
        "phoneNr": "Partner phone number",
        "regNr": "Company registration number",
        "vatNr": "Company VAT number",
        "representative": "Company representative",
        "dateJoined": "Partner joining date",
        "isadmin": "Is Admin",
        "integrations": {
            "label": "Integrations"
        }
    },
    "adminSettings": "Admin Settings",
    "adminOrders": "Admin Orders",
    "partnerManagement": "Partner Management",
    "userManagement": "User Management",
    "reviewProductChanges": "Review Product Changes",
    "eventLog": "Event Log",
    "order": {
        "name": "Order name",
        "creationTime": "Created at",
        "status": {
            "label": "Order status",
            "error": "Error",
            "emailSkipped": "Error",
            "emailSent": "Waiting to be po",
            "packageReceived": "Package received",
            "packagePosted": "Package has been posted",
            "cancelled": "Cancelled"
        },
        "item": {
            "name": "Product name",
            "quantity": "Quantity",
            "retailPrice": "Retail price (with VAT)",
            "partnerPrice": "Partner price (without VAT)"
        },
        "generateReport": "Generate report"
    },
    "orderReport": {
        "title": "Print Report",
        "startDate": "Start date",
        "endDate": "End date",
        "print": "Print"
    },
    "product": {
        "name": "Product name",
        "partner": "Partner",
        "save": "Save",
        "approve": "Approve changes",
        "action": {
            "saved": {
                "admin": "Product saved.",
                "label": "Product saved. The changes will go live after a review from Ehestu staff."
            },
            "imageUploaded": "Image uploaded.",
            "imageUploadFailed": "Image upload failed. Maximum file size is 10MB.",
            "imageDeleted": "Image deleted.",
            "inventoryUpdated": "Inventory updated successfully",
            "review": {
                "approved": "Review approved"
            }
        },
        "addVariant": "Add variant",
        "variant": {
            "available": "Available",
            "trackQuantity": "Track quantity",
            "save": "Save",
            "name": "Variant name",
            "option": {
                "name": "Option name",
                "value": "Option value",
                "nameError": "Please select option name",
                "valueError": "Please select option value"
            },
            "nameError": "Please enter variant name",
            "image": {
                "title": "Update variant image"
            },
            "deliveryTime": {
                "name": "Delivery Time (in business days)"
            }
        },
        "images": "Images",
        "inReview": "(in review)",
        "variants": "Variants",
        "new": {
            "label": "New product",
            "description": "Please enter a title for the new product",
            "create": "Create"
        },
        "inventory": {
            "edit": "Edit inventory",
            "label": "Inventory",
            "disabled": "Please save the product first before updating inventory"
        },
        "organization": "Product organization",
        "subcategory": "Category",
        "superCategory": "Class",
        "material": "Material",
        "stone": "Stone",
        "collection": "Collection",
        "metal": "Metal",
        "collectionTooltip": "You can choose an existing collection or enter a new one",
        "margins": "Margin",
        "attribute": {
            "add": "Add attribute"
        },
        "description": {
            "change": "Change description"
        }
    },
    "cancel": "Cancel",
    "description": "Description",
    "save": "Save",
    "partner": {
        "name": "Partner name",
        "new": "New partner"
    },
    "admin": {
        "settings": {
            "fulfill": "Automatically fulfill orders in Shopify",
            "reminder": "Send reminder emails every Mon, Wed, Fri",
            "receiveOrders": "Receive new orders from shopify",
            "sendEmails": "Send order emails to partners",
            "label": "Admin Settings",
            "newPartner": "Send a notification email on new partner",
            "orderChange": "Send a notification email on order change"
        }
    },
    "feedback": {
        "send": {
            "succesful": "Feedback successfully sent",
            "action": "Send"
        },
        "title": "Send feedback",
        "description": "Kindly inform us if you experience any issues with Ehestu Partner or have any recommendations on enhancing the website's usability"
    },
    "card": {
        "close": "Close"
    },
    "login": {
        "title": "Please sign in with your Google account"
    },
    "collections": "Collections",
    "collection": {
        "name": "Collection name",
        "edit": "Edit collection"
    },
    "user": {
        "new": "New user",
        "management": "User Management"
    },
    "integrations": {
        "woo": {
            "label": "WooCommerce integration",
            "enable": "Enable Integration with WooCommerce",
            "createSuccess": "WooCommerce integration successfully enabled",
            "alreadyExists": "WooCommerce integration already exists",
            "notConfigured": "Please set a homepage before enabling WooCommerce integration",
            "siteNotReachable": "Store website address is not reachable. Please double check the partner homepage.",
            "siteNotWordpress": "Configured homepage is not a valid Wordpress site.",
            "installation": {
                "1": "Step 1: Download the plugin zip file by clicking the button below.",
                "2": "Step 2: Go to your WordPress admin dashboard and navigate to Plugins -> Add New.",
                "3": "Step 3: Click on the 'Upload Plugin' button at the top of the page.",
                "4": "Step 4: Choose the downloaded zip file and click 'Install Now'.",
                "5": "Step 5: Once the plugin has been installed, click 'Activate Plugin'.",
                "6": "Step 6: Reload this page",
                "title": "Plugin has not been installed yet.",
                "header": "Plugin Installation Instructions:",
                "download": "download plugin"
            },
            "error": "There was an issue with your WooCommerce integration. Please contact a staff member to resolve this issue.",
            "update": "There is a new version of our Plugin available. Please update it in your WordPress admin dashboard."
        }
    }
}

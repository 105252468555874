import gql from "graphql-tag";

export const GET_PARTNER_ORDERS = gql`
    query PartnerOrders($filter: PartnerOrderFilter!) {
        partnerOrders(filter: $filter) {
            status
            smartpostShippingData {
                barcode
            }
            shippingMethod
            partner {
                externalName
            }
            order {
                name
                creationTime
            }
            lineItems {
                name
                quantity
                currentQuantity

                originalUnitPriceSet {
                    fragments {
                        shopMoneyBag {
                            shopMoney {
                                amount
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_PARTNER_ORDERS_WITHOUT_LINEITEMS = gql`
    query PartnerOrdersWithoutLineitems($filter: PartnerOrderFilter!) {
        partnerOrders(filter: $filter) {
            status
            smartpostShippingData {
                barcode
            }
            shippingMethod
            partner {
                externalName
                name
            }
            order {
                name
                creationTime
            }
            canFulfill
            hasLabel
        }
    }
`
import currency from "currency.js";
import {Partner} from "@/graphql/types/types";

export const eur = (value: string | number) => currency(value, { symbol: '€'})

export const vat = eur("1.2");

export const charge = (partner: Partner | null | undefined) => {
    if (!partner)
        return eur(1);

    return eur(1).add(eur(partner.partnerCharge).divide(100));
}

export const applyCharges = (itemCost: currency, partner: Partner | null | undefined): currency => {
    return itemCost.divide(vat).divide(charge(partner))
}

export const numberRules = [(v: any) => !!v || 'This field is required', (v: string) => /^([1-9][0-9]*)(\.\d\d?)?$/.test(v) || "Please enter a correct value"]

export const integerRules =[(v: any) => !!v || 'This field is required', (v: any) => !isNaN(v) || /^-?([0-9]*)$/.test(v) || "Please enter a correct value"]
import gql from "graphql-tag";

export const GET_USER = gql`
    query UserQuery {
        currentUser {
            name
            isAdmin
            loggedIn
            partner {
                id
                partnerCharge
                name
                email
                externalName
                additionalEmails
                globalDeliveryTime {
                    min
                    max
                }
                partnerDetails {
                    description
                    homePage
                    iban
                    logoUrl
                    phoneNr
                    registryCode
                    representative
                    vatCode
                    dateJoined
                }
                itemSpecificCharge
                
                wooIntegration {
                    health
                    
                }
            }
            profilePictureUrl
            
        }
    }
`


export const GET_USER_BY_ID = gql`
    query UserByIdQuery($id: Int!) {
        userById(id: $id) {
            id
            name
            email
            isAdmin
            loggedIn
            partner {
                name
                id
            }
        }
    }
`

export const GET_USERS = gql`
query UsersQuery {
    users {
        id
        email
        isAdmin
        name
        partner {
            email
            name
        }
    }
}
`

export const SAVE_USER = gql`
mutation SaveUserMutation($input: SaveUserInput) {
    saveUser(input: $input) {
        id
    }
}
`
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import Vue from "vue";
import VueApollo from "vue-apollo";
import {createHttpLink} from "apollo-link-http";
import {ApolloLink, from} from "apollo-link";
// @ts-ignore
import {ErrorResponse, onError} from "apollo-link-error";
import { ServerParseError } from 'apollo-link-http-common'
import router from "@/router";
import store from "@/store";


const cache = new InMemoryCache();

const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: (process.env.VUE_APP_API_URL ?? "") + '/graphql',

    credentials: 'same-origin'
})

const errorLink = onError((x: ErrorResponse) => {

    if (x.graphQLErrors) {
        store.commit("setError", x.graphQLErrors[0].message);
    }

    if (x.networkError && (x.networkError as ServerParseError).statusCode.toString().startsWith("4")) {
        if (router.currentRoute.name !== "login")
            router.push("/login").catch();
    }

    if (x.networkError && (x.networkError as ServerParseError).statusCode.toString().startsWith("5")) {
        store.commit("setError", "Server error");
    }
})
//
// const authLink = new ApolloLink((operation, forward) => {
//     operation.setContext((context: Record<string, any>) => {
//         let headers = {
//             ...context.headers
//         };
//
//         if (window.localStorage.getItem(authToken)) {
//             headers.Authorization = getAuth();
//         }
//
//         return {
//             headers: headers
//         };
//     });
//
//     return forward(operation);
// });

const apolloClient = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    errorHandler(error) {
    }
})

export default apolloProvider;

export { ApolloClient, cache }

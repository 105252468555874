export default {
    "home": {
        "totalSales": "M\u00fc\u00fcgitulu",
        "itemsSold": "Tooteid m\u00fc\u00fcdud",
        "todo": "Minu aktiivsed tellimused",
        "orderName": "Tellimuse nimi",
        "createdAt": "Loomiskuup\u00e4ev",
        "downloadLabel": "Lae pakisilt alla",
        "last30day": "Viimase 30 p\u00e4eva \u00fclevaade"
    },
    "downloadLabel": "Lae alla",
    "logout": "Logi v\u00e4lja",
    "homePage": "Esilehele",
    "orders": "Minu tellimused",
    "products": "Minu tooted",
    "settings": {
        "label": "Minu seaded",
        "error": {
            "name": "Palun sisesta nimi",
            "emailMissing": "Palun sisesta e-aadress",
            "charge": "Juurdehindlus peab olema 0 ja 100% vahel",
            "emailValid": "Palun sisesta korrektne e-aadress",
            "emailTooLong": "E-kirja maksimaalne pikkus on 225 t\u00e4hem\u00e4rki",
            "iban": "Palun sisesta korrektne IBAN number",
            "textTooLong": "Teksti maksimaalne pikkus on 1024 t\u00e4hem\u00e4rki",
            "phoneNr": "Palun sisesta korrektne telefoninumber",
            "validDate": "Palun sisesta kuup\u00e4ev formaadis DD.MM.YYYY",
            "partnerMissing": "Palun vali Partner"
        },
        "action": {
            "saved": "Seaded salvestatud"
        },
        "partnerName": "Partneri nimi",
        "externalName": "Partneri nimi",
        "email": "Emaili aadress",
        "upcharge": "Ehestu juurdehindlus",
        "chargeExpl": {
            "text1": "Jaehind arvestatakse j\u00e4rgmise valemi alusel",
            "text2": "Partneri hind * k\u00e4ibemaks * (1 + juurdehindlus / 100)",
            "text3": "N\u00e4iteks"
        },
        "chargePerItem": "Juurdehindlust saab igal tootel eraldi m\u00e4\u00e4rata",
        "deliveryTime": {
            "label": "Tarneaeg t\u00f6\u00f6p\u00e4evades",
            "min": "Alates",
            "max": "Kuni"
        },
        "additionalEmails": "T\u00e4iendavad e-aadressid",
        "addAdditionalEmail": "Lisa t\u00e4iendav e-aadress",
        "generalPartnerInfo": "Partneri \u00fcldinfo",
        "partnerDescription": "Partneri profiil ja tutvustus",
        "homepage": "Partneri koduleht",
        "iban": "Partneri ettev\u00f5tte IBAN",
        "logoUrl": "Partneri logo link",
        "phoneNr": "Parnteri telefoninumber",
        "regNr": "Partneri ettev\u00f5tte registreerimisnumber",
        "vatNr": "Partneri ettev\u00f5tte KMKR number",
        "representative": "Partneri kunstniku v\u00f5i disaineri nimi",
        "dateJoined": "Partneri \u00fchinemise kuup\u00e4ev",
        "isadmin": "Kasutaja on administraator",
        "integrations": {
            "label": "Liidestused"
        }
    },
    "adminSettings": "Administraatori seaded",
    "adminOrders": "Tellimuste haldus",
    "partnerManagement": "Partnerite haldus",
    "userManagement": "Kasutajate haldus",
    "reviewProductChanges": "Tootelehtede \u00fclevaatus",
    "eventLog": "Logiraamat",
    "order": {
        "name": "Tellimusnumber",
        "creationTime": "Tellimuse esitamise kuup\u00e4ev",
        "status": {
            "label": "Tellimuse staatus",
            "error": "Viga tellimuses",
            "emailSkipped": "Viga tellimuses",
            "emailSent": "Tellimus ootab postitamist",
            "packageReceived": "Tellimus t\u00e4idetud",
            "packagePosted": "Tellimus teel",
            "cancelled": "T\u00fchistatud"
        },
        "item": {
            "name": "Toote nimetus",
            "quantity": "Kogus",
            "retailPrice": "Jaehind sh k\u00e4ibemaks 20%",
            "partnerPrice": "Partneri hind ilma k\u00e4ibemaksuta"
        },
        "generateReport": "Tellimuste aruanne"
    },
    "orderReport": {
        "title": "Tr\u00fcki tellimuste aruanne",
        "startDate": "Alguskuup\u00e4ev",
        "endDate": "L\u00f5ppkuup\u00e4ev",
        "print": "Tr\u00fcki"
    },
    "product": {
        "name": "Toote nimetus",
        "partner": "Partner",
        "save": "Salvesta",
        "approve": "N\u00f5ustu muudatustega tootelehel",
        "action": {
            "saved": {
                "admin": "Muudatused tootelehel salvestatud",
                "label": "Toode salvestatud. Muudatused avaldatakse Ehestu poolt p\u00e4rast \u00fclevaatust"
            },
            "imageUploaded": "Pilt \u00fcles laetud. Muudatus avaldatakse Ehestu poolt p\u00e4rast \u00fclevaatust",
            "imageUploadFailed": "Pildi \u00fcleslaadimine eba\u00f5nnestus",
            "imageDeleted": "Pilt kustutatud.",
            "inventoryUpdated": "Laoseisu uuendamine \u00f5nnestus",
            "review": {
                "approved": "Tooteleht uuendatud"
            }
        },
        "addVariant": "Lisa tootele lahendusvariant",
        "variant": {
            "available": "Laoseis",
            "trackQuantity": "Laoseisu arvestus ja j\u00e4lgimine",
            "save": "Salvesta",
            "name": "Toote lahendusvariandi nimetus",
            "option": {
                "name": "Variandi valiku nimi",
                "value": "Variandi valiku v\u00e4\u00e4rtus",
                "nameError": "Palun sisestage nimi",
                "valueError": "Palun sisestage v\u00e4\u00e4rtus"
            },
            "nameError": "Palun sisestage nimi",
            "image": {
                "title": "Lahendusvariandi pildi uuendamine"
            },
            "deliveryTime": {
                "name": "Tarneaeg (t\u00f6\u00f6p\u00e4evades)"
            }
        },
        "images": "Pildid",
        "inReview": "(tooteleht \u00fclevaatamisel)",
        "variants": "Lahendusvariandid",
        "new": {
            "label": "Uus toode",
            "description": "Palun sisestage uue toote nimi",
            "create": "Loo toode"
        },
        "inventory": {
            "edit": "Laoseisu uuendamine",
            "label": "Laoseis",
            "disabled": "Palun salvesta toode enne laoseisu muutmist"
        },
        "organization": "Toote kategooriad",
        "subcategory": "Kategooria",
        "superCategory": "Klass",
        "material": "Materjal",
        "stone": "Kivi",
        "collection": "Kollektsioon",
        "metal": "Metall",
        "collectionTooltip": "Valige olemasolev kollektsioon v\u00f5i sisestage uue kollektsiooni nimi",
        "margins": "Marginaal",
        "attribute": {
            "add": "Lisa kirjelduse v\u00e4li"
        },
        "description": {
            "change": "Muuda kirjeldust"
        }
    },
    "cancel": "T\u00fchista",
    "description": "Kirjeldus",
    "save": "Salvesta",
    "partner": {
        "name": "Partneri nimi",
        "new": "Uus partner"
    },
    "admin": {
        "settings": {
            "fulfill": "Toote postitamisel automaatselt t\u00e4idetakse Shopify tellimus",
            "reminder": "Partneritele saadetakse postitamata tellimuste kohta meeldetuletus iga E, K ja R",
            "receiveOrders": "S\u00fcsteem v\u00f5tab vastu uusi tellimusi Shopifyst",
            "sendEmails": "Partneritele saadetakse tellimuse tekkimisel e-kiri",
            "label": "S\u00fcsteemi haldus",
            "newPartner": "Saada email info@ehestu.ee aadressile uue partneri tekkel",
            "orderChange": "Saada email info@ehestu.ee aadressile tellimuse muudatuste korral"
        }
    },
    "feedback": {
        "send": {
            "succesful": "Tagasiside edastatud",
            "action": "Saada"
        },
        "title": "Saada tagasisidet",
        "description": "Palun teavitage meid, kui teil tekib probleeme Ehestu Partneriga v\u00f5i kui teil on ettepanekuid kuidas portaali mugavamaks teha"
    },
    "card": {
        "close": "Sulge"
    },
    "login": {
        "title": "Sisene oma Google kasutajaga"
    },
    "collections": "Kollektsioonid",
    "collection": {
        "name": "Kollektsiooni nimi",
        "edit": "Muuda kollektsiooni"
    },
    "user": {
        "new": "Uus kasutaja",
        "management": "Kasutaja haldus"
    },
    "integrations": {
        "woo": {
            "label": "WooCommerce liidestus",
            "enable": "Luba liidestus WooCommerce poega",
            "createSuccess": "WooCommerce poega liidestus lubatud",
            "alreadyExists": "WooCommerce liidestus on juba lubatud",
            "notConfigured": "Palun sisesta kodulehe aadress enne WooCommerce liidestuse lubamist",
            "siteNotReachable": "Koduleht ei ole k\u00e4ttesaadav",
            "siteNotWordpress": "Koduleht ei kasuta Wordpressi",
            "installation": {
                "1": "1. samm: Laadige alla plugin zip-fail, kl\u00f5psates allolevat nuppu.",
                "2": "2. samm: Minge oma WordPressi administraatoripaneelile ja navigeerige Plugins -> Add New.",
                "3": "3. samm: Kl\u00f5psake lehe \u00fclaosas nuppu 'Upload Plugin'.",
                "4": "4. samm: Valige alla laaditud zip-fail ja kl\u00f5psake 'Install Now'.",
                "5": "5. samm: Kui plugin on installitud, kl\u00f5psake 'Activate Plugin'.",
                "6": "6. samm: Laadi see leht uuesti",
                "title": "Liidestus ei ole veel seadistatud.",
                "header": "Liidestuse installimise juhised:",
                "download": "Laadi plugin alla"
            },
            "error": "Teie poe WooCommerce liidestusega on tekkinud t\u00f5rge. Palun v\u00f5tke meiega \u00fchendust.",
            "update": "Liidestusest on uus versioon saadaval. Palun uuendage liidestus oma WordPressi administraatoripaneelis."
        }
    }
}

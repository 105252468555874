import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbar: false,
        snackbarText: "",
        isError: false,
        timeout: 2000
    },
    mutations: {
        setSnackbar(state, snackbarText) {
            state.snackbar = true;
            state.isError = false;
            state.snackbarText = snackbarText;
            state.timeout = 2000;
        },

        setError(state, snackbarText) {
            state.snackbar = true;
            state.isError = true;
            state.snackbarText = snackbarText;
            state.timeout = 10000;
        },

        hideSnackbar(state) {
            state.snackbar = false;
        },
    },
    actions: {},
    modules: {}
})



import {Component, Vue, Watch} from 'vue-property-decorator';
import {GET_USER} from "@/graphql/queries/user";
import {LineItem, Order, OrderFilter, PartnerOrder, PartnerOrderStatus, Product, User} from "@/graphql/types/types";
import moment from "moment";
import currency from 'currency.js';
import {applyCharges, eur} from "@/tools/money";
import {DataTableHeader} from "vuetify";
import {formatTime} from '@/tools/time';
import {t} from "@/plugins/translations/t";
import {GET_PARTNER_ORDERS, GET_PARTNER_ORDERS_WITHOUT_LINEITEMS} from "@/graphql/queries/partnerOrder";


@Component({
    apollo: {
        user: {
            query: GET_USER,
            update: data => data.currentUser
        }
    }
})
export default class Home extends Vue {

    private user: User | null = null;
    private partnerOrders: PartnerOrder[] = [];
    private ordersToBePosted: PartnerOrder[] = []
    private formatTime = formatTime;

    get orderHeaders(): DataTableHeader[] {
        return [
            {
                text: t("home.orderName"),
                value: "order.name"
            },
            {
                text: t("home.createdAt"),
                value: "order.creationTime"
            },
            {
                text: t("home.downloadLabel"),
                value: "download"
            }
        ]
    }

    @Watch("user", {deep: true, immediate: true})
    userChanged() {
        if (!this.user) {
            return;
        }

        this.ordersLoading = true;
        this.toBePostedLoading = true;

        this.$apollo.query({
            query: GET_PARTNER_ORDERS_WITHOUT_LINEITEMS,
            variables: {
                filter: {
                    hasLabel: true,
                    status: PartnerOrderStatus.EmailSent,
                    partnerName: this.user.partner?.name
                }

            }
        }).then(res => {
            this.ordersToBePosted = res.data.partnerOrders;
            this.toBePostedLoading = false;
        })

        this.$apollo.query({
            query: GET_PARTNER_ORDERS,
            variables: {
                    filter: this.filter
                }

        }).then(res => {
            this.partnerOrders = res.data.partnerOrders;
            this.ordersLoading = false;
        })
    }

    downloadLabel(partnerOrder: PartnerOrder) {
        window.location.href = "/api/downloadLabel?order=" + encodeURIComponent(partnerOrder.order.name);
    }

    private ordersLoading = false;
    private toBePostedLoading = false;

    get filter(): OrderFilter {
        return {
            from: moment().subtract(1, 'month').toDate(),
            partnerName: this.user?.partner?.name
        }
    }

    get itemsCount() {
        return this.partnerOrders
            .flatMap(o => o.lineItems ?? [])
            .reduce((prev: number, item: LineItem) => {
                return prev + item.quantity
            }, 0)
    }

    get orderSum() {
        const orderSum: currency = this.partnerOrders
            .flatMap(o => o.lineItems ?? [])
            .reduce((prev: currency, item: LineItem) => {
                const itemCost = eur(item.originalUnitPriceSet.fragments?.shopMoneyBag?.shopMoney.amount ?? 0)
                    .multiply(item.quantity);

                return prev.add(applyCharges(itemCost, this.user?.partner));
            }, eur(0));

        return orderSum.format()
    }

};

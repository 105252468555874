import Vue from 'vue';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import en from 'vuetify/src/locale/en'
import et from 'vuetify/src/locale/et'

import customEt from "@/plugins/translations/customEt";
import customEn from "@/plugins/translations/customEn";

const messages = {
    en: {
        $vuetify: {
            ...en
        },
        ...customEn
    },
    et: {
        $vuetify: {
            ...et
        },
        ...customEt
    },
}

const i18n = new VueI18n({
    locale: 'et', // set locale
    messages, // set locale messages
})

const t = (key: string) => {
    if (!key) {
        return '';
    }
    return i18n.tc(key);
};

export default i18n;
export {t};
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  /**
   * A signed decimal number, which supports arbitrary precision and is serialized as a string.
   *
   * Example values: `"29.99"`, `"29.999"`.
   */
  Decimal: string;
  NonNegativeInt: any;
};

export type AdminSettings = {
  __typename?: 'AdminSettings';
  fulfillOrders: Scalars['Boolean'];
  sendEmailsToPartners: Scalars['Boolean'];
  sendNewPartnerEmails: Scalars['Boolean'];
  sendReminderEmails: Scalars['Boolean'];
  webhookExists: Scalars['Boolean'];
  wooSyncInterval: Scalars['Int'];
};

export enum ChangeLogInitiator {
  Ehestu = 'EHESTU',
  Partner = 'PARTNER'
}

export type CreateReviewOutput = {
  __typename?: 'CreateReviewOutput';
  imageMappings: Array<IdMapping>;
  reviewId: Scalars['String'];
  variantMappings: Array<IdMapping>;
};

export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type DeliveryTimeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type Event = {
  __typename?: 'Event';
  content?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<EventType>;
  id: Scalars['Int'];
  user?: Maybe<User>;
};

export enum EventType {
  CollectionEdited = 'COLLECTION_EDITED',
  FulfillmentError = 'FULFILLMENT_ERROR',
  InventoryCountUpdated = 'INVENTORY_COUNT_UPDATED',
  OrderFulfilled = 'ORDER_FULFILLED',
  PartnerCreated = 'PARTNER_CREATED',
  PartnerEdited = 'PARTNER_EDITED',
  ProductEdited = 'PRODUCT_EDITED',
  ProductInventoryEdited = 'PRODUCT_INVENTORY_EDITED',
  ProductReviewApproved = 'PRODUCT_REVIEW_APPROVED',
  ProductReviewCreated = 'PRODUCT_REVIEW_CREATED',
  SummaryError = 'SUMMARY_ERROR',
  SummarySent = 'SUMMARY_SENT',
  SummarySkipped = 'SUMMARY_SKIPPED',
  UserCreated = 'USER_CREATED',
  UserEdited = 'USER_EDITED',
  VendorOrderError = 'VENDOR_ORDER_ERROR',
  VendorOrderSent = 'VENDOR_ORDER_SENT',
  VendorOrderSkipped = 'VENDOR_ORDER_SKIPPED'
}

export type Fragments = {
  shopMoneyBag?: Maybe<MoneyBag>;
};

export type IdMapping = {
  __typename?: 'IdMapping';
  originalId: Scalars['String'];
  reviewId: Scalars['String'];
};

/** Represents an image resource. */
export type Image = {
  __typename?: 'Image';
  /**
   * The location of the image as a URL.
   *
   * If no transform options are specified, then the original image will be preserved including any pre-applied transforms.
   *
   * All transformation options are considered "best-effort". Any transformation that the original image type doesn't support will be ignored.
   *
   * If you need multiple variations of the same image, then you can use [GraphQL aliases](https://graphql.org/learn/queries/#aliases).
   */
  url: Scalars['String'];
};

export type ImageWithId = {
  __typename?: 'ImageWithId';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export enum IntegrationCreateStatus {
  AlreadyExists = 'ALREADY_EXISTS',
  Ok = 'OK',
  SiteNotConfigured = 'SITE_NOT_CONFIGURED',
  SiteNotReachable = 'SITE_NOT_REACHABLE',
  SiteNotWordpress = 'SITE_NOT_WORDPRESS'
}

export enum IntegrationUpdateStatus {
  Error = 'ERROR',
  NotConfigured = 'NOT_CONFIGURED',
  NotFound = 'NOT_FOUND',
  Ok = 'OK',
  SiteNotReachable = 'SITE_NOT_REACHABLE',
  SiteNotWordpress = 'SITE_NOT_WORDPRESS'
}

/**
 * Represents the goods available to be shipped to a customer.
 * It holds essential information about the goods, including SKU and whether it is tracked.
 */
export type InventoryItem = {
  __typename?: 'InventoryItem';
  /** Unit cost associated with the inventory item. */
  fragments?: Maybe<LineItemFragments>;
  id: Scalars['ID'];
  /** The inventory item's quantities at the specified location. */
  inventoryLevel?: Maybe<InventoryLevel>;
  /** Whether inventory levels are tracked for the item. */
  tracked: Scalars['Boolean'];
};

/** The quantities of an inventory item that are related to a specific location. */
export type InventoryLevel = {
  __typename?: 'InventoryLevel';
  /** The quantity of inventory items that are available at the inventory level's associated location. */
  available: Scalars['Int'];
};

/** Represents a single line item on an order. */
export type LineItem = Node & {
  __typename?: 'LineItem';
  /** The line item's quantity, minus the removed quantity. */
  currentQuantity: Scalars['Int'];
  /** A globally-unique identifier. */
  id: Scalars['ID'];
  /** The name of the product. */
  name: Scalars['String'];
  /** The variant unit price without discounts applied, in shop and presentment currencies. */
  originalUnitPriceSet: TotalUnitPriceSet;
  /** The number of variant units ordered. */
  quantity: Scalars['Int'];
  /** The name of the vendor who made the variant. */
  vendor?: Maybe<Scalars['String']>;
};

export type LineItemFragments = {
  __typename?: 'LineItemFragments';
  inventoryItemUnitCost?: Maybe<UnitCost>;
};

/**
 * A collection of monetary values in their respective currencies. Typically used in the context of multi-currency pricing and transactions,
 * when an amount in the shop's currency is converted to the customer's currency of choice (the presentment currency).
 */
export type MoneyBag = {
  __typename?: 'MoneyBag';
  /** Amount in shop currency. */
  shopMoney: MoneyV2;
};

/** A monetary value with currency. */
export type MoneyV2 = {
  __typename?: 'MoneyV2';
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
};

export type MoneyV3 = {
  __typename?: 'MoneyV3';
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveProductReview?: Maybe<Scalars['String']>;
  createNewProduct?: Maybe<Scalars['String']>;
  createReviewProduct: CreateReviewOutput;
  createWooIntegration: WooIntegrationCreateResult;
  deleteProductImage?: Maybe<Scalars['String']>;
  editWebhook?: Maybe<Scalars['String']>;
  fulfillOrder?: Maybe<Scalars['String']>;
  resendPartnerOrder?: Maybe<Scalars['String']>;
  savePartner?: Maybe<Scalars['String']>;
  saveProduct?: Maybe<Scalars['String']>;
  saveSettings?: Maybe<Scalars['String']>;
  saveUser?: Maybe<User>;
  sendFeedback?: Maybe<Scalars['String']>;
  triggerRefresh: Scalars['String'];
  updateCollectionInventory?: Maybe<Scalars['String']>;
  updateProductInventory?: Maybe<Scalars['String']>;
};


export type MutationApproveProductReviewArgs = {
  reviewId: Scalars['String'];
};


export type MutationCreateNewProductArgs = {
  title: Scalars['String'];
};


export type MutationCreateReviewProductArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateWooIntegrationArgs = {
  partnerId: Scalars['Int'];
};


export type MutationDeleteProductImageArgs = {
  imageId: Scalars['String'];
  product: Scalars['String'];
};


export type MutationEditWebhookArgs = {
  isCreating?: InputMaybe<Scalars['Boolean']>;
};


export type MutationFulfillOrderArgs = {
  barcode?: InputMaybe<Scalars['String']>;
  notifyCustomer: Scalars['Boolean'];
  orderName: Scalars['String'];
  partner: Scalars['String'];
};


export type MutationResendPartnerOrderArgs = {
  newLabel: Scalars['Boolean'];
  order: Scalars['String'];
  partner: Scalars['String'];
};


export type MutationSavePartnerArgs = {
  input?: InputMaybe<SavePartnerInput>;
};


export type MutationSaveProductArgs = {
  input?: InputMaybe<SaveProductInput>;
};


export type MutationSaveSettingsArgs = {
  input?: InputMaybe<SettingsInput>;
};


export type MutationSaveUserArgs = {
  input?: InputMaybe<SaveUserInput>;
};


export type MutationSendFeedbackArgs = {
  feedback: Scalars['String'];
};


export type MutationUpdateCollectionInventoryArgs = {
  collection: Scalars['String'];
  deliveryTime?: InputMaybe<Scalars['String']>;
  tracked: Scalars['Boolean'];
  value: Scalars['Int'];
};


export type MutationUpdateProductInventoryArgs = {
  deliveryTime?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  level: Scalars['Int'];
  tracked: Scalars['Boolean'];
};

export type Node = {
  /** A globally-unique identifier. */
  id: Scalars['ID'];
};

export type OmnivaShippingData = {
  __typename?: 'OmnivaShippingData';
  barcode: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  creationTime: Scalars['DateTime'];
  customerName: Scalars['String'];
  name: Scalars['String'];
  status: PartnerOrderStatus;
  totalAmount: Scalars['String'];
};

export type OrderFilter = {
  from?: InputMaybe<Scalars['DateTime']>;
  orderName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
};

export type Pagination = {
  after?: InputMaybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  additionalEmails: Array<Scalars['String']>;
  email: Scalars['String'];
  externalName: Scalars['String'];
  globalDeliveryTime: DeliveryTime;
  id: Scalars['Int'];
  itemSpecificCharge: Scalars['Boolean'];
  name: Scalars['String'];
  partnerCharge: Scalars['NonNegativeInt'];
  partnerDetails: PartnerDetails;
  wooIntegration?: Maybe<WooIntegration>;
};

export type PartnerDetails = {
  __typename?: 'PartnerDetails';
  dateJoined?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  homePage?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  phoneNr?: Maybe<Scalars['String']>;
  registryCode?: Maybe<Scalars['String']>;
  representative?: Maybe<Scalars['String']>;
  vatCode?: Maybe<Scalars['String']>;
};

export type PartnerDetailsInput = {
  dateJoined?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  homePage?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  phoneNr?: InputMaybe<Scalars['String']>;
  registryCode?: InputMaybe<Scalars['String']>;
  representative?: InputMaybe<Scalars['String']>;
  vatCode?: InputMaybe<Scalars['String']>;
};

export type PartnerOrder = {
  __typename?: 'PartnerOrder';
  canFulfill: Scalars['Boolean'];
  hasLabel: Scalars['Boolean'];
  lineItems: Array<LineItem>;
  order: Order;
  partner: Partner;
  shippingMethod?: Maybe<ShippingMethod>;
  smartpostShippingData?: Maybe<SmartpostShippingData>;
  status: PartnerOrderStatus;
};

export type PartnerOrderFilter = {
  from?: InputMaybe<Scalars['DateTime']>;
  hasLabel?: InputMaybe<Scalars['Boolean']>;
  orderName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PartnerOrderStatus>;
};

export enum PartnerOrderStatus {
  Cancelled = 'CANCELLED',
  EmailSent = 'EMAIL_SENT',
  EmailSkipped = 'EMAIL_SKIPPED',
  Error = 'ERROR',
  New = 'NEW',
  OutOfStock = 'OUT_OF_STOCK',
  PackageArrived = 'PACKAGE_ARRIVED',
  PackagePosted = 'PACKAGE_POSTED',
  PackageReceived = 'PACKAGE_RECEIVED',
  Processing = 'PROCESSING',
  Regenerate = 'REGENERATE'
}

export type Product = {
  __typename?: 'Product';
  cursor: Scalars['String'];
  /** The featured image for the product. */
  featuredImage?: Maybe<Image>;
  /** A unique human-friendly string of the product's title. */
  handle: Scalars['String'];
  id: Scalars['ID'];
  partner: Partner;
  reviewOf?: Maybe<Scalars['String']>;
  /** The title of the product. */
  title: Scalars['String'];
  /** The name of the product's vendor. */
  vendor: Scalars['String'];
};

/** The Product resource lets you manage products in a merchant’s store. You can use [ProductVariants](https://shopify.dev/api/admin-graphql/latest/objects/productvariant) to create or update different versions of the same product. You can also add or update product [Media](https://shopify.dev/api/admin-graphql/latest/interfaces/media). Products can be organized by grouping them into a [Collection](https://shopify.dev/api/admin-graphql/latest/objects/collection). */
export type ProductById = {
  __typename?: 'ProductById';
  collection?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<Scalars['String']>;
  descriptionHtml: Scalars['String'];
  featuredImage?: Maybe<Scalars['String']>;
  /** A unique human-friendly string of the product's title. */
  handle: Scalars['String'];
  /** Whether the product has only a single variant with the default option and value. */
  hasOnlyDefaultVariant: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The images associated with the product. */
  images: Array<ImageWithId>;
  material?: Maybe<Scalars['String']>;
  metal?: Maybe<Scalars['String']>;
  options: Array<ProductOption>;
  partner: Partner;
  review?: Maybe<Scalars['String']>;
  stone?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  superCategory?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  /** The title of the product. */
  title: Scalars['String'];
  type: Scalars['String'];
  variants: Array<ProductVariant>;
  /** The name of the product's vendor. */
  vendor: Scalars['String'];
};

export type ProductCollection = {
  __typename?: 'ProductCollection';
  deliveryTime: Scalars['String'];
  inventory: Scalars['Int'];
  name: Scalars['String'];
  tracked: Scalars['Boolean'];
};

export type ProductOption = {
  __typename?: 'ProductOption';
  /** The product option’s name. */
  name: Scalars['String'];
  /** The corresponding value to the product option name. */
  values: Array<Scalars['String']>;
};

export type ProductReview = {
  __typename?: 'ProductReview';
  id: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
};

/** The set of valid sort keys for the Product query. */
export enum ProductSortKeys {
  /** Sort by the `created_at` value. */
  CreatedAt = 'CREATED_AT',
  /** Sort by the `id` value. */
  Id = 'ID',
  /** Sort by the `inventory_total` value. */
  InventoryTotal = 'INVENTORY_TOTAL',
  /** Sort by the `product_type` value. */
  ProductType = 'PRODUCT_TYPE',
  /** Sort by the `published_at` value. */
  PublishedAt = 'PUBLISHED_AT',
  /**
   * Sort by relevance to the search terms when the `query` parameter is specified on the connection.
   * Don't use this sort key when no search query is specified.
   */
  Relevance = 'RELEVANCE',
  /** Sort by the `title` value. */
  Title = 'TITLE',
  /** Sort by the `updated_at` value. */
  UpdatedAt = 'UPDATED_AT',
  /** Sort by the `vendor` value. */
  Vendor = 'VENDOR'
}

export type ProductVariant = {
  __typename?: 'ProductVariant';
  /** The date and time when the variant was created. */
  createdAt: Scalars['DateTime'];
  deliveryTime?: Maybe<Scalars['String']>;
  /** Display name of the variant, based on product's title + variant's title. */
  displayName: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<VariantImage>;
  /** The inventory item, which is used to query for inventory information. */
  inventoryItem: InventoryItem;
  /** The price of the product variant in the default shop currency. */
  price: Scalars['String'];
  /** List of product options applied to the variant. */
  selectedOptions: Array<SelectedOption>;
  sku?: Maybe<Scalars['String']>;
  /** The title of the product variant. */
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adminSettings?: Maybe<AdminSettings>;
  collections: Array<ProductCollection>;
  currentUser?: Maybe<User>;
  events: Array<Event>;
  jobByName?: Maybe<TriggerInfo>;
  materials: Array<Scalars['String']>;
  metals: Array<Scalars['String']>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  partnerOrders: Array<PartnerOrder>;
  partners: Array<Partner>;
  product?: Maybe<ProductById>;
  productReviews: Array<ProductReview>;
  productTypes: Array<Scalars['String']>;
  products: Array<Product>;
  stones: Array<Scalars['String']>;
  subcategories: Array<Scalars['String']>;
  superCategories: Array<Scalars['String']>;
  userById: User;
  users: Array<User>;
  wooIntegration?: Maybe<WooIntegration>;
  wooIntegrations: Array<WooIntegration>;
  wooInventoryChangeLogs: Array<WooInventoryChangeLog>;
  wooProductUpdates: Array<WooProductUpdate>;
};


export type QueryCollectionsArgs = {
  name?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
};


export type QueryEventsArgs = {
  eventType?: InputMaybe<EventType>;
  pagination?: InputMaybe<Pagination>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryJobByNameArgs = {
  jobName: Scalars['String'];
};


export type QueryOrderArgs = {
  name: Scalars['String'];
};


export type QueryOrdersArgs = {
  filter: OrderFilter;
  pagination?: InputMaybe<Pagination>;
};


export type QueryPartnerOrdersArgs = {
  filter: PartnerOrderFilter;
  pagination?: InputMaybe<Pagination>;
};


export type QueryPartnersArgs = {
  filter?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryProductReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  onlyReviews?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ProductSortKeys>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Scalars['String']>;
};


export type QueryWooIntegrationArgs = {
  partnerId: Scalars['Int'];
};


export type QueryWooInventoryChangeLogsArgs = {
  pagination: Pagination;
  partnerId: Scalars['Int'];
};


export type QueryWooProductUpdatesArgs = {
  changeLogId: Scalars['Int'];
};

export type SavePartnerInput = {
  additionalEmails: Array<Scalars['String']>;
  email: Scalars['String'];
  externalName: Scalars['String'];
  globalDeliveryTime: DeliveryTimeInput;
  id?: InputMaybe<Scalars['Int']>;
  itemSpecificCharge: Scalars['Boolean'];
  name: Scalars['String'];
  partnerCharge: Scalars['NonNegativeInt'];
  partnerDetails: PartnerDetailsInput;
};

export type SaveProductInput = {
  collection?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  material?: InputMaybe<Scalars['String']>;
  metal?: InputMaybe<Scalars['String']>;
  options: Array<Scalars['String']>;
  partner: Scalars['String'];
  stone?: InputMaybe<Scalars['String']>;
  subcategory?: InputMaybe<Scalars['String']>;
  superCategory?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  variants: Array<VariantInput>;
};

export type SaveUserInput = {
  email?: InputMaybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type SelectedOption = {
  __typename?: 'SelectedOption';
  /** The product option’s name. */
  name: Scalars['String'];
  /** The product option’s value. */
  value: Scalars['String'];
};

export type SettingsInput = {
  fulfillOrders: Scalars['Boolean'];
  sendEmailsToPartners: Scalars['Boolean'];
  sendNewPartnerEmails: Scalars['Boolean'];
  sendReminderEmails: Scalars['Boolean'];
};

export enum ShippingMethod {
  Smartpost = 'SMARTPOST'
}

export type SmartpostShippingData = {
  __typename?: 'SmartpostShippingData';
  barcode: Scalars['String'];
};

export type TotalUnitPriceSet = {
  __typename?: 'TotalUnitPriceSet';
  fragments?: Maybe<TotalUnitPriceSetFragments>;
};

export type TotalUnitPriceSetFragments = Fragments & {
  __typename?: 'TotalUnitPriceSetFragments';
  shopMoneyBag?: Maybe<MoneyBag>;
};

export type TriggerInfo = {
  __typename?: 'TriggerInfo';
  nextFireTime: Scalars['Float'];
};

export type UnitCost = {
  __typename?: 'UnitCost';
  unitCost?: Maybe<MoneyV3>;
};

export type UpdatedProduct = {
  __typename?: 'UpdatedProduct';
  id: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  loggedIn: Scalars['Boolean'];
  name: Scalars['String'];
  partner?: Maybe<Partner>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type VariantImage = {
  __typename?: 'VariantImage';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  url: Scalars['String'];
};

export type VariantInput = {
  deliveryTime?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  imageId?: InputMaybe<Scalars['String']>;
  options: Array<Scalars['String']>;
  price: Scalars['String'];
  sku: Scalars['String'];
  title: Scalars['String'];
  tracked: Scalars['Boolean'];
  unitCost: Scalars['String'];
};

export enum WooHealthStatus {
  Error = 'ERROR',
  Fail = 'FAIL',
  NotConfigured = 'NOT_CONFIGURED',
  Ok = 'OK'
}

export type WooIntegration = {
  __typename?: 'WooIntegration';
  health: WooHealthStatus;
  partner: Partner;
  status: WooIntegrationStatus;
  version?: Maybe<Scalars['String']>;
};

export type WooIntegrationCreateResult = {
  __typename?: 'WooIntegrationCreateResult';
  status: IntegrationCreateStatus;
};

export enum WooIntegrationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type WooInventoryChangeLog = {
  __typename?: 'WooInventoryChangeLog';
  changeInitiator: ChangeLogInitiator;
  eventTime: Scalars['DateTime'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  status: WooInventoryChangeLogStatus;
};

export enum WooInventoryChangeLogStatus {
  Failure = 'FAILURE',
  PartialSuccess = 'PARTIAL_SUCCESS',
  Success = 'SUCCESS'
}

export enum WooProductFailureType {
  SkuNotFoundInEhestu = 'SKU_NOT_FOUND_IN_EHESTU',
  SkuNotFoundInPartner = 'SKU_NOT_FOUND_IN_PARTNER'
}

export type WooProductUpdate = {
  __typename?: 'WooProductUpdate';
  failureType?: Maybe<WooProductFailureType>;
  id: Scalars['ID'];
  inventoryChange?: Maybe<Scalars['Int']>;
  link: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
  status: WooProductUpdateStatus;
};

export enum WooProductUpdateStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type AdminSettingsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSettingsQueryQuery = { __typename?: 'Query', adminSettings?: { __typename?: 'AdminSettings', sendEmailsToPartners: boolean, webhookExists: boolean, fulfillOrders: boolean, sendReminderEmails: boolean } | null };

export type SaveSettingsMutationVariables = Exact<{
  input?: InputMaybe<SettingsInput>;
}>;


export type SaveSettingsMutation = { __typename?: 'Mutation', saveSettings?: string | null };

export type EditWebhookMutationVariables = Exact<{
  isCreating?: InputMaybe<Scalars['Boolean']>;
}>;


export type EditWebhookMutation = { __typename?: 'Mutation', editWebhook?: string | null };

export type SendFeedbackMutationVariables = Exact<{
  feedback: Scalars['String'];
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', sendFeedback?: string | null };

export type TriggerRefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type TriggerRefreshMutation = { __typename?: 'Mutation', triggerRefresh: string };

export type TriggerInfoQueryQueryVariables = Exact<{
  jobName: Scalars['String'];
}>;


export type TriggerInfoQueryQuery = { __typename?: 'Query', jobByName?: { __typename?: 'TriggerInfo', nextFireTime: number } | null };

export type GetEventsQueryVariables = Exact<{
  itemCount: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type GetEventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: number, eventType?: EventType | null, eventTime?: Date | null, content?: string | null, user?: { __typename?: 'User', name: string } | null }> };

export type GetEventIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventIdsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: number }> };

export type CreateWooIntegrationMutationVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type CreateWooIntegrationMutation = { __typename?: 'Mutation', createWooIntegration: { __typename?: 'WooIntegrationCreateResult', status: IntegrationCreateStatus } };

export type GetWooIntegrationQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetWooIntegrationQuery = { __typename?: 'Query', wooIntegration?: { __typename?: 'WooIntegration', status: WooIntegrationStatus, health: WooHealthStatus, version?: string | null } | null };

export type GetWooInventoryChangeLogsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
  pagination: Pagination;
}>;


export type GetWooInventoryChangeLogsQuery = { __typename?: 'Query', wooInventoryChangeLogs: Array<{ __typename?: 'WooInventoryChangeLog', id: string, status: WooInventoryChangeLogStatus, partnerName: string, changeInitiator: ChangeLogInitiator, eventTime: Date, message?: string | null, orderName?: string | null }> };

export type OrdersQueryVariables = Exact<{
  filter: OrderFilter;
  pagination?: InputMaybe<Pagination>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', name: string, customerName: string, totalAmount: string, creationTime: Date }> };

export type ResendOrderMutationVariables = Exact<{
  order: Scalars['String'];
  partner: Scalars['String'];
  newLabel: Scalars['Boolean'];
}>;


export type ResendOrderMutation = { __typename?: 'Mutation', resendPartnerOrder?: string | null };

export type ShallowPartnerOrdersQueryVariables = Exact<{
  filter: OrderFilter;
}>;


export type ShallowPartnerOrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', name: string, totalAmount: string, customerName: string, creationTime: Date, status: PartnerOrderStatus }> };

export type FulfillOrderMutationVariables = Exact<{
  partner: Scalars['String'];
  barcode: Scalars['String'];
  notifyCustomer: Scalars['Boolean'];
  orderName: Scalars['String'];
}>;


export type FulfillOrderMutation = { __typename?: 'Mutation', fulfillOrder?: string | null };

export type PartnersQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type PartnersQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'Partner', name: string, email: string, id: number, partnerCharge: any, externalName: string, additionalEmails: Array<string>, itemSpecificCharge: boolean, globalDeliveryTime: { __typename?: 'DeliveryTime', max?: number | null, min?: number | null }, partnerDetails: { __typename?: 'PartnerDetails', description?: string | null, homePage?: string | null, iban?: string | null, logoUrl?: string | null, phoneNr?: string | null, registryCode?: string | null, representative?: string | null, vatCode?: string | null, dateJoined?: string | null } }> };

export type SavePartnerMutationVariables = Exact<{
  input?: InputMaybe<SavePartnerInput>;
}>;


export type SavePartnerMutation = { __typename?: 'Mutation', savePartner?: string | null };

export type PartnersForFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnersForFilterQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'Partner', id: number, externalName: string, name: string }> };

export type PartnerOrdersQueryVariables = Exact<{
  filter: PartnerOrderFilter;
}>;


export type PartnerOrdersQuery = { __typename?: 'Query', partnerOrders: Array<{ __typename?: 'PartnerOrder', status: PartnerOrderStatus, shippingMethod?: ShippingMethod | null, smartpostShippingData?: { __typename?: 'SmartpostShippingData', barcode: string } | null, partner: { __typename?: 'Partner', externalName: string }, order: { __typename?: 'Order', name: string, creationTime: Date }, lineItems: Array<{ __typename?: 'LineItem', name: string, quantity: number, currentQuantity: number, originalUnitPriceSet: { __typename?: 'TotalUnitPriceSet', fragments?: { __typename?: 'TotalUnitPriceSetFragments', shopMoneyBag?: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'MoneyV2', amount: string } } | null } | null } }> }> };

export type PartnerOrdersWithoutLineitemsQueryVariables = Exact<{
  filter: PartnerOrderFilter;
}>;


export type PartnerOrdersWithoutLineitemsQuery = { __typename?: 'Query', partnerOrders: Array<{ __typename?: 'PartnerOrder', status: PartnerOrderStatus, shippingMethod?: ShippingMethod | null, canFulfill: boolean, hasLabel: boolean, smartpostShippingData?: { __typename?: 'SmartpostShippingData', barcode: string } | null, partner: { __typename?: 'Partner', externalName: string, name: string }, order: { __typename?: 'Order', name: string, creationTime: Date } }> };

export type ShallowProductsQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ProductSortKeys>;
  onlyReviews?: InputMaybe<Scalars['Boolean']>;
}>;


export type ShallowProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, cursor: string, vendor: string, title: string, handle: string, reviewOf?: string | null, featuredImage?: { __typename?: 'Image', url: string } | null }> };

export type ProductReviewQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductReviewQuery = { __typename?: 'Query', product?: { __typename?: 'ProductById', review?: string | null, id: string } | null };

export type CreateProductReviewMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
}>;


export type CreateProductReviewMutation = { __typename?: 'Mutation', createReviewProduct: { __typename?: 'CreateReviewOutput', reviewId: string, imageMappings: Array<{ __typename?: 'IdMapping', originalId: string, reviewId: string }>, variantMappings: Array<{ __typename?: 'IdMapping', reviewId: string, originalId: string }> } };

export type ProductPartnerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductPartnerQuery = { __typename?: 'Query', product?: { __typename?: 'ProductById', vendor: string } | null };

export type ProductVariantIdsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductVariantIdsQuery = { __typename?: 'Query', product?: { __typename?: 'ProductById', id: string, variants: Array<{ __typename?: 'ProductVariant', id: string }> } | null };

export type CreateNewProductMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateNewProductMutation = { __typename?: 'Mutation', createNewProduct?: string | null };

export type ProductByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductByIdQuery = { __typename?: 'Query', product?: { __typename?: 'ProductById', review?: string | null, handle: string, id: string, vendor: string, title: string, featuredImage?: string | null, descriptionHtml: string, hasOnlyDefaultVariant: boolean, type: string, deliveryTime?: string | null, metal?: string | null, collection?: string | null, superCategory?: string | null, subcategory?: string | null, material?: string | null, stone?: string | null, variants: Array<{ __typename?: 'ProductVariant', id: string, createdAt: Date, displayName: string, price: string, title: string, sku?: string | null, deliveryTime?: string | null, selectedOptions: Array<{ __typename?: 'SelectedOption', value: string, name: string }>, inventoryItem: { __typename?: 'InventoryItem', tracked: boolean, inventoryLevel?: { __typename?: 'InventoryLevel', available: number } | null, fragments?: { __typename?: 'LineItemFragments', inventoryItemUnitCost?: { __typename?: 'UnitCost', unitCost?: { __typename?: 'MoneyV3', amount: string } | null } | null } | null }, image?: { __typename?: 'VariantImage', url: string, thumbnail: string, id: string } | null }>, images: Array<{ __typename?: 'ImageWithId', id: string, url: string }>, partner: { __typename?: 'Partner', name: string, partnerCharge: any, itemSpecificCharge: boolean, externalName: string } } | null };

export type UpdateProductMutationVariables = Exact<{
  input?: InputMaybe<SaveProductInput>;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', saveProduct?: string | null };

export type ApproveReviewMutationVariables = Exact<{
  reviewId: Scalars['String'];
}>;


export type ApproveReviewMutation = { __typename?: 'Mutation', approveProductReview?: string | null };

export type DeleteProductImageMutationVariables = Exact<{
  product: Scalars['String'];
  imageId: Scalars['String'];
}>;


export type DeleteProductImageMutation = { __typename?: 'Mutation', deleteProductImage?: string | null };

export type UpdateProductInventoryMutationVariables = Exact<{
  variantId: Scalars['String'];
  tracked: Scalars['Boolean'];
  level: Scalars['Int'];
  deliveryTime?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProductInventoryMutation = { __typename?: 'Mutation', updateProductInventory?: string | null };

export type ProductReviewsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
}>;


export type ProductReviewsQuery = { __typename?: 'Query', productReviews: Array<{ __typename?: 'ProductReview', id: string }> };

export type MetalsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MetalsQueryQuery = { __typename?: 'Query', metals: Array<string> };

export type SuperCategoriesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SuperCategoriesQueryQuery = { __typename?: 'Query', superCategories: Array<string> };

export type SubcategoriesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SubcategoriesQueryQuery = { __typename?: 'Query', subcategories: Array<string> };

export type CollectionsQueryQueryVariables = Exact<{
  partner: Scalars['String'];
}>;


export type CollectionsQueryQuery = { __typename?: 'Query', collections: Array<{ __typename?: 'ProductCollection', name: string }> };

export type CollectionsDetailsQueryQueryVariables = Exact<{
  partner: Scalars['String'];
  name: Scalars['String'];
}>;


export type CollectionsDetailsQueryQuery = { __typename?: 'Query', collections: Array<{ __typename?: 'ProductCollection', name: string, inventory: number, deliveryTime: string, tracked: boolean }> };

export type StonesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type StonesQueryQuery = { __typename?: 'Query', stones: Array<string> };

export type MaterialsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MaterialsQueryQuery = { __typename?: 'Query', materials: Array<string> };

export type UpdateCollectionMutationVariables = Exact<{
  collection: Scalars['String'];
  value: Scalars['Int'];
  tracked: Scalars['Boolean'];
  deliveryTime: Scalars['String'];
}>;


export type UpdateCollectionMutation = { __typename?: 'Mutation', updateCollectionInventory?: string | null };

export type UserQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQueryQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', name: string, isAdmin: boolean, loggedIn: boolean, profilePictureUrl?: string | null, partner?: { __typename?: 'Partner', id: number, partnerCharge: any, name: string, email: string, externalName: string, additionalEmails: Array<string>, itemSpecificCharge: boolean, globalDeliveryTime: { __typename?: 'DeliveryTime', min?: number | null, max?: number | null }, partnerDetails: { __typename?: 'PartnerDetails', description?: string | null, homePage?: string | null, iban?: string | null, logoUrl?: string | null, phoneNr?: string | null, registryCode?: string | null, representative?: string | null, vatCode?: string | null, dateJoined?: string | null }, wooIntegration?: { __typename?: 'WooIntegration', health: WooHealthStatus } | null } | null } | null };

export type UserByIdQueryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserByIdQueryQuery = { __typename?: 'Query', userById: { __typename?: 'User', id: number, name: string, email: string, isAdmin: boolean, loggedIn: boolean, partner?: { __typename?: 'Partner', name: string, id: number } | null } };

export type UsersQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQueryQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, email: string, isAdmin: boolean, name: string, partner?: { __typename?: 'Partner', email: string, name: string } | null }> };

export type SaveUserMutationMutationVariables = Exact<{
  input?: InputMaybe<SaveUserInput>;
}>;


export type SaveUserMutationMutation = { __typename?: 'Mutation', saveUser?: { __typename?: 'User', id: number } | null };

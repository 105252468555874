import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import RouterView from "../router/RouterView.vue"
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/translations/t";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/:lang(en|et)',
        component: RouterView,
        name: "base",
        children: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: 'adminorders',
                name: 'adminorders',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "adminOrders" */ '../views/AdminOrders.vue')
            },
            {
                path: 'orders',
                name: 'partnerorders',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partnerOrders" */ '../views/PartnerOrders.vue')
            },
            {
                path: 'settings',
                name: 'partnersettings',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "partnerSettings" */ '../views/pages/PartnerSettings.vue')
            },
            {
                path: 'partners/:partnerId',
                name: 'editPartner',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "editPartner" */ '../views/pages/PartnerSettings.vue')
            },
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
            },
            {
                path: 'admin',
                name: 'admin',
                component: () => import(/* webpackChunkName: "adminsettings" */ '../views/Admin.vue')
            },
            {
                path: 'partners',
                name: 'partners',
                component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue')
            },
            {
                path: 'eventlog',
                name: 'eventlog',
                component: () => import(/* webpackChunkName: "logpage" */ '../views/EventLog.vue')
            },
            {
                path: 'users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
            },
            {
                path: 'users/:userId',
                name: 'user',
                component: () => import(/* webpackChunkName: "user" */ '../views/pages/User.vue')
            },
            {
                path: 'products',
                name: 'products',
                component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
            },
            {
                path: 'collections',
                name: 'collections',
                component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue')
            },
            {
                path: 'products/review',
                name: 'review',
                component: () => import(/* webpackChunkName: "review" */ '../views/Products.vue')
            },
            {
                path: 'products/new',
                name: 'newProduct',
                component: () => import(/* webpackChunkName: "newProduct" */ '../views/pages/Product.vue')
            },
            {
                path: 'products/:id',
                name: 'product',
                component: () => import(/* webpackChunkName: "product" */ '../views/pages/Product.vue')
            },
            {
                path: 'products/review/:id',
                name: 'productReview',
                component: () => import(/* webpackChunkName: "productReview" */ '../views/pages/Product.vue')
            },
            {
                path: 'integrations/woo/:id?',
                name: 'wooIntegration',
                component: () => import(/* webpackChunkName: "wooIntegration" */ '../views/WooIntegrationView.vue')
            },
            {
                path: '(.*)',
                redirect: () => {
                    return "/" + i18n.locale;
                }
            }
        ]
    },
    {
        path: "/(.*)",
        redirect: (to: any) => {
            return "/et" + to.path;
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// router.beforeEach((to, from, next) => {
//     let language = to.params.lang;
//     if (!language) {
//         language = 'et';
//     }
//
//     i18n.locale = language
//     next();
// });

export default router

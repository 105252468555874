import gql from "graphql-tag";

export const GET_SHALLOW_PRODUCTS = gql`
    query ShallowProducts($title: String, $first: Int!, $after: String, $partnerId: Int, $reverse: Boolean, $sort: ProductSortKeys, $onlyReviews: Boolean) {
        products (title: $title, first: $first, after: $after, partnerId: $partnerId, reverse: $reverse, sort: $sort, onlyReviews: $onlyReviews) {
            id
            cursor
            vendor
            title
            handle
            featuredImage {
                url
            }
            reviewOf
        }
    }
`

export const GET_PRODUCT_REVIEW = gql`
    query ProductReview($id: String!) {
        product(id: $id) {
            review
            id
        }
    }
`

export const CREATE_PRODUCT_REVIEW = gql`
    mutation CreateProductReview($id: String!, $title: String!) {
        createReviewProduct(id: $id, title: $title) {
            imageMappings {
                originalId
                reviewId
            }
            reviewId
            variantMappings {
                reviewId
                originalId
            }
        }
    }
`

export const GET_PRODUCT_PARTNER = gql`
    query ProductPartner($id: String!) {
        product(id: $id) {
            vendor
        }
    }
`

export const GET_PRODUCT_VARIANT_IDS = gql`
    query ProductVariantIds($id: String!) {
        product(id: $id) {
            id
            variants {
                id
            }
        }
    }
`

export const CREATE_NEW_PRODUCT = gql`
    mutation CreateNewProduct($title: String!) {
        createNewProduct(title: $title)
    }
`

export const GET_SINGLE_PRODUCT = gql`
    query ProductById($id: String!) {
        product(id: $id) {
            review
            handle
            id
            vendor
            title
            featuredImage
            descriptionHtml
            hasOnlyDefaultVariant
            type
            deliveryTime
            variants {
                id
                createdAt
                selectedOptions {
                    value
                    name
                }
                displayName
                price
                title
                sku
                inventoryItem {
                    tracked
                    inventoryLevel {
                        available
                    }
                    fragments {
                        inventoryItemUnitCost {
                            unitCost {
                                amount
                            }
                        }
                    }
                }

                image {
                    url
                    thumbnail
                    id
                }
                deliveryTime
            }

            images {
                id
                url
            }

            partner {
                name
                partnerCharge
                itemSpecificCharge
                externalName
            }
            
            metal
            collection
            superCategory
            subcategory
            material
            stone
        }
    }
`

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($input: SaveProductInput) {
        saveProduct(input: $input)
    }
`

export const APPROVE_REVIEW = gql`
    mutation ApproveReview($reviewId: String!) {
        approveProductReview(reviewId: $reviewId)
    }
`

export const DELETE_IMAGE = gql`
    mutation DeleteProductImage($product: String!, $imageId: String!) {
        deleteProductImage(product: $product, imageId: $imageId)
    }
`

export const UPDATE_PRODUCT_INVENTORY = gql`
    mutation UpdateProductInventory($variantId: String!, $tracked: Boolean!, $level: Int!, $deliveryTime: String){
        updateProductInventory(tracked: $tracked, id: $variantId, level: $level, deliveryTime: $deliveryTime)
    }
`

export const GET_PRODUCT_REVIEWS = gql`
    query ProductReviews($after: String) {
        productReviews(after: $after) {
            id
        }
    }
`


export const GET_METALS = gql`
    query MetalsQuery {
        metals
    }
`

export const GET_SUPERCATEGORIES = gql`
    query SuperCategoriesQuery {
        superCategories
    }
`

export const GET_SUBCATEGORIES = gql`
    query SubcategoriesQuery {
        subcategories
    }
`

export const GET_COLLECTIONS = gql`
    query CollectionsQuery($partner: String!) {
        collections(partnerName: $partner) {
            name
        }
    }
`

export const GET_COLLECTION_DETAILS = gql`
    query CollectionsDetailsQuery($partner: String!, $name: String!) {
        collections(partnerName: $partner, name: $name) {
            name
            inventory
            deliveryTime
            tracked
        }
    }
`

export const GET_STONES = gql`
    query StonesQuery {
        stones
    }
`

export const GET_MATERIALS = gql`
    query MaterialsQuery {
        materials
    }
`

export const UPDATE_COLLECTION = gql`
    mutation UpdateCollection($collection: String!, $value: Int!, $tracked: Boolean!, $deliveryTime: String!) {
        updateCollectionInventory(collection: $collection, value: $value, tracked: $tracked, deliveryTime: $deliveryTime)
    }
`
import gql from "graphql-tag";

export const GET_ADMIN_SETTINGS = gql`
    query AdminSettingsQuery {
        adminSettings {
            sendEmailsToPartners
            webhookExists
            fulfillOrders
            sendReminderEmails
        }
    }
`

export const SAVE_ADMIN_SETTINGS = gql`
    mutation SaveSettings ($input: SettingsInput) {
        saveSettings(input: $input)
    }
`


export const EDIT_WEBHOOK = gql`
    mutation EditWebhook ($isCreating: Boolean) {
        editWebhook(isCreating: $isCreating)
    }
`

export const SEND_FEEDBACK = gql`
    mutation SendFeedback($feedback: String!) {
        sendFeedback(feedback: $feedback)
    }
`

export const TRIGGER_COLLECTION_REFRESH = gql`
mutation TriggerRefresh {
    triggerRefresh
}
`

export const GET_CRON_DETAILS = gql`
    query TriggerInfoQuery($jobName: String!) {
        jobByName(jobName: $jobName) {
            nextFireTime
        }
    }
`

import {Component, Provide, Vue, Watch} from "vue-property-decorator";
import {GET_USER} from "@/graphql/queries/user";
import {User} from "@/graphql/types/types";
import AccountNotSetUp from "@/components/AccountNotSetUp.vue";
import axios from "axios";
import {GET_PRODUCT_REVIEWS} from "@/graphql/queries/product";
import i18n from "@/plugins/translations/t";
import FeedbackButton from "@/components/FeedbackButton.vue";
import {provide} from "vue";

@Component({
    components: {AccountNotSetUp, FeedbackButton},
    apollo: {
        user: {
            query: GET_USER,
            update: data => data.currentUser
        },
    }
})
export default class App extends Vue {

    private user!: User;
    private currentView: string = this.$route.name ?? "home";
    private productReviews: string = ""

    drawer = false;

    get showWooIntegration() {
        return this.user?.partner?.wooIntegration !== null;
    }

    get authenticated() {
        return this.user?.loggedIn ?? false;
    }

    get loading() {
        return this.$apollo.queries.user.loading;
    }

    get isMobile() {
        return this.$vuetify.breakpoint.name === "xs";
    }

    get isDrawer() {
        return !this.isMobile || this.drawer;
    }

    set isDrawer(val: boolean) {
        this.drawer = !this.isMobile || val;
    }

    get pictureUrl(): string {
        return this.user?.profilePictureUrl ?? "";
    }

    get currentLang() {
        return i18n.locale;
    }

    set currentLang(val: string) {
        i18n.locale = val
        this.$router.push({name: this.$route.name ?? "", params: {...this.$route.params, lang: val}})
    }

    created() {
        if (window.location.pathname.startsWith("/en")) {
            i18n.locale = "en";
        }

        if (window.location.pathname.startsWith("/et")) {
            i18n.locale = "et";
        }
    }

    private languages = [
        {
            text: "Eesti",
            value: "et"
        },
        {
            text: "English",
            value: "en"
        }
    ]



    @Provide("reloadUser")
    reloadUser() {
        this.$apollo.queries.user.refetch()
    }

    @Watch("user", {deep: true})
    userChanged() {
        if (this.user && !this.user.loggedIn && this.$route.name !== "login") {
            this.$router.push({name: "login", params: {lang: this.$route.params.lang}})
        }

        if (this.user && this.user.loggedIn && this.$route.name === "login") {
            this.$router.push({name: "home", params: {lang: this.$route.params.lang}});
        }

        this.showOverlay = !!(this.user && !this.user.isAdmin && !this.user.partner);

        if (this.user?.isAdmin) {
            this.$apollo.query({
                query: GET_PRODUCT_REVIEWS,
                variables: {
                    after: null
                }
            }).then(res => {
                const count = res.data.productReviews.length
                this.productReviews = count < 10 ? count.toString() : "9+"
            })
        }
    }

    private showOverlay: boolean = false;

    @Watch("$route")
    routeChanged() {
        this.currentView = this.$route.name ?? "home"

        i18n.locale = this.$route.params.lang ?? "et";
    }

    @Watch("currentView")
    currentViewChanged(curr: string | undefined, prev: string | undefined) {
        this.isDrawer = false;

        if (curr === undefined || prev === undefined) {

            if (prev !== undefined) {
                this.currentView = prev;
            }

            return;
        }

        if (curr !== prev && this.$route.name !== curr) {
            this.$router.push({name: curr, params: {lang: this.$route.params.lang}})
        }
    }


    logout() {
        axios.post("/api/public/logout")
            .then(() => {
                window.localStorage.setItem("hasLoggedIn", "");
                this.$apollo.getClient().resetStore();
                this.user.loggedIn = false;

                if (this.$route.name !== "login") {
                    this.$router.push({name: "login", params: {lang: this.$route.params.lang}});
                }
            });
    }

    set snackbar(val: boolean) {
        if (!val) {
            this.$store.commit("hideSnackbar");
        }
    }

    get snackbar() {
        return this.$store.state.snackbar
    }

    get snackbarText() {
        return this.$store.state.snackbarText;
    }

    get snackbarColor() {
        return this.$store.state.isError ? "error" : "";
    }

    get timeout() {
        return this.$store.state.timeout ?? 2000;
    }

};
